
import EmailService from "../../Services/EmailService";
import FECHAS from "@/Constantes/Fecha";
export default {
  name: "ProductoGestor",
  components: {
    CardEmailDetalle: () => import("./componentes/CardEmailDetalle.vue"),
  },
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Emails enviados" }],
      registroOpciones: FECHAS.FECHAS_FILTRO_REGISTRO,
      filtros: {
        busqueda: "",
        fechaInicio: "",
      },
      usuarioId: localStorage.getItem("autorId"),
      emails: [],
      columnas: [
        {
          name: "folioAccion",
          header: "#Email",
          align: "center",
          field: "folio_email",
          minWidth: 80,
          width: 100,
          templateName: "folioAccion",
          rutaId: "mensaje_email_id",
        },
        {
          name: "folio",
          header: "#OP",
          align: "center",
          field: "oportunidad_folio",
          minWidth: 85,
          width: 85,
          templateName: "folio",
          routeName: "oportunidadesDetalle",
          rutaId: "oportunidad_id",
          type: "string",
        },
        {
          name: "articulo",
          header: "Artículo",
          align: "left",
          field: "articulo_titulo",
          minWidth: 160,
          width: 180,
        },
        {
          name: "contacto",
          header: "Contacto",
          align: "left",
          field: "contacto_nombre_completo",
          minWidth: 160,
          width: 180,
        },
        {
          name: "email",
          header: "Email",
          align: "left",
          field: "destinatario_email",
          minWidth: 160,
          width: 180,
        },
        {
          name: "asunto",
          header: "Asunto",
          align: "left",
          field: "asunto",
          minWidth: 160,
          width: 200,
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          statusName: "status_nombre",
          minWidth: 100,
          width: 120,
          templateName: "status",
          filter: false,
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 120,
          width: 155,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Autor",
          align: "left",
          field: "registro_autor",
          minWidth: 100,
          width: 140,
        },
      ],
      accionLimpiar: false,
      emailObj: {},
    };
  },
  mounted() {
    this.cargaInicial();
  },
  computed: {},
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerEmails(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        fechaInicio: query.fi || "",
        ordenar: "registro_fecha_desc",
        usuarioId: this.usuarioId,
        emailId: query.eid || "",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        fi: this.filtros.fechaInicio,
        eid: this.filtros.emailId,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerEmails(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await EmailService.listarGestor(this.filtros)
        .then(async (resp) => {
          let emails = resp;
          this.emails = emails.map((e) => {
            return {
              ...e,
              registro_fecha: new Date(e.registro_fecha),
            };
          });
          if (this.filtros.emailId)
            await this.obtenerDetalle(this.filtros.emailId, false, false);
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerDetalle(emailId, replaceUrl = true, loader = true) {
      if (loader) this.loader(true);
      if (replaceUrl) {
        this.filtros.emailId = emailId;
        this.replaceUrl();
      }
      let datos = {
        emailId: emailId,
      };
      await EmailService.obtenerDetalle(datos)
        .then((resp) => {
          this.emailObj = this.emails.find(
            (e) => e.mensaje_email_id == emailId
          );
          // Se actualiza email seleccionado
          this.actualizarEmailSeleccionado(emailId);
          this.emailObj.archivos = resp.archivos;
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
        })
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        fechaInicio: "",
        ordenar: "registro_fecha_desc",
        usuarioId: this.usuarioId,
      };
      this.obtenerEmails();
      this.accionLimpiar = true;
      this.emailObj = {};
    },
    actualizarEmailSeleccionado(emailId) {
      let emails = _.cloneDeep(this.emails);
      this.emails = [];
      emails.map((e) => {
        e.itemSeleccionado = emailId == e.mensaje_email_id ? true : false;
      });
      this.emails = emails;
    },
  },
};
